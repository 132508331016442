import { DcBaseComponent } from '@deleteagency/dc';
import Flickity from 'flickity';

const CAROUSEL_SIMPLE_ARROW_SHAPE =
    'M18.5,50c-0.1-2.7,1.1-5.3,3.1-7l42-40c4.1-4,10.6-4,14.7,0c3.9,3.4,4.2,9.3,0.8,13.2c-0.3,0.3-0.5,0.6-0.8,0.8L43.7,50l34.6,33c3.9,3.4,4.2,9.3,0.8,13.2c-0.3,0.3-0.5,0.6-0.8,0.8c-4.1,4-10.6,4-14.7,0l-42-40C19.6,55.3,18.4,52.7,18.5,50z';

export default class LatestArticlesCarouselComponent extends DcBaseComponent {
    _carousel = null;

    static getNamespace() {
        return 'latest-articles-car';
    }

    onInit() {
        this._initDescktopFlickity();
        this._carousel = new Flickity(this.element, {
            wrapAround: true,
            cellAlign: 'left',
            prevNextButtons: true,
            watchCSS: true,
            pageDots: this.options.pageDots ?? true,
            adaptiveHeight: false,
            arrowShape: CAROUSEL_SIMPLE_ARROW_SHAPE,
            on: {
                ready: function() {
                    this.element.classList.add('flickity-ready');
                },
            },
        });
    }

    _initDescktopFlickity() {
        const childrenCount = this.element.children.length;
        if (childrenCount > 3) {
            this.element.classList.add('flick-car--desktop-only');
        }
    }

    onDestroy() {
        this._carousel.destroy();
        this._carousel = null;
    }
}
